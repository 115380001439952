<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import {
  IonApp, IonRouterOutlet,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  mounted() {
    this.setGeneralParameters();
  },
  computed: {
    ...mapState(['generalParameters']),
  },
  methods: {
    ...mapActions(['getGeneralParameters']),
    setGeneralParameters() {
      this.getGeneralParameters();
    },
  },
});
</script>
