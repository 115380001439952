import { io } from 'socket.io-client';

class SocketClient {
  constructor() {
    this.socket = io(process.env.VUE_APP_SOCKET_URL, {
      withCredentials: true,
    });

    this.socket.connect();
  }

  disconnect() {
    return this.socket.disconnect();
  }

  informDelay(data) {
    return this.socket.emit('inform-delay', data);
  }

  signalDriverPosition(data) {
    return this.socket.emit('signal-driver-position', data);
  }

  arrivedPickup(data) {
    return this.socket.emit('arrive-pickup', data);
  }

  leftPickup(data) {
    return this.socket.emit('leave-pickup', data);
  }

  watchDriver(driver) {
    return this.socket.emit('watch-driver', driver);
  }

  watchMonitor() {
    return this.socket.emit('watch-monitor');
  }

  unwatchMonitor() {
    return this.socket.emit('unwatch-monitor');
  }

  watchDelay(callback) {
    return this.socket.on('inform-delay', callback);
  }

  informAvailability(data) {
    return this.socket.emit('update-pax', data);
  }

  watchDriverPosition(callback) {
    return this.socket.on('signal-driver-position', callback);
  }

  watchPickupChange(callback) {
    return this.socket.on('pickup-change', callback);
  }

  toggleBookNr(data) {
    return this.socket.emit('toggle-bookNr', data);
  }

  watchBookNr(callback) {
    return this.socket.on('toggle-bookNr', callback);
  }

  watchDriverChanges(callback) {
    return this.socket.on('update-pax', callback);
  }
}

export default new SocketClient();
