<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar style="display: block">
        <ion-button
          v-show="isScanning"
          size="large"
          expand="block"
          @click="stopScan">
          {{ $t('buttons.stop') }}
        </ion-button>

        <ion-button
          v-show="!isScanning && !viewSingleDay"
          @click="goBack" color="secondary" fill="clear" slot="start">
          <ion-icon :icon="backIcon" />
        </ion-button>

        <ion-buttons
          v-show="!isScanning && viewSingleDay"
          slot="start"
          id="sidenav-toggle-excursions">
          <ion-menu-button menu="sidenav-excursions"></ion-menu-button>
        </ion-buttons>

        <div name="nav-title" slot="secondary" class="ion-padding-end">
          <h1 v-show="!isScanning" class="text-no-overflow">
            {{ pageTitle }}
          </h1>
        </div>
      </ion-toolbar>

      <ion-row
        v-show="drivingTabs && drivingTabs.length >= 2">
        <ion-col
          v-for="(excursion, key) in drivingTabs"
          v-show="excdatebusID"
          style="padding: 0;"
          :key="key">
          <ion-button
            expand="full"
            color="secondary"
            size="lg"
            style="font-size: 1.5em"
            :disabled="excursion.ExcDateBus === route.params.excdatebusID"
            :href="parseExcursionPath(excursion)">
            {{ excursion.ExcursShortcut }}

            <span v-show="isGuide" class="ion-padding">
              {{ excursion.Driver }}
            </span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-header>

    <ion-content
      v-show="!isScanning">
      <ion-progress-bar v-if="loading" type="indeterminate"/>
      <ion-tabs>
        <ion-router-outlet animated="false"></ion-router-outlet>

        <ion-tab-bar
          slot="bottom">
          <ion-tab-button tab="drive" :href="parsePath('drive')">
            <ion-label>
              <ion-icon size="large" :icon="carIcon" />
              <div>
                {{ $t('nav.drive') }}
              </div>
            </ion-label>
          </ion-tab-button>

          <ion-tab-button
            v-show="isGuide"
            tab="check-in"
            :href="parsePath('check-in')">
            <ion-label>
              <ion-icon size="large" :icon="barcodeIcon" />
              <div>
                {{ $t('nav.check-in') }}
              </div>
            </ion-label>
          </ion-tab-button>

          <ion-tab-button tab="reservation-list" :href="parsePath('reservation-list')">
            <ion-label>
              <ion-icon size="large" :icon="listIcon" />
              <div>
                {{ $t('nav.reservation-list') }}
              </div>
            </ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>

    <ion-menu
      v-if="user"
      v-show="!isScanning && viewSingleDay"
      side="start"
      menu-id="sidenav-excursions"
      content-id="sidenav-toggle-excursions">
      <ion-card class="ion-padding">
        <ion-card-subtitle>
          <ion-badge v-show="isGuide">
            {{ $t('fields.guide') }}
          </ion-badge>
          <ion-badge v-show="isDriver">
            {{ $t('fields.driver') }}
          </ion-badge>
        </ion-card-subtitle>

        <ion-card-title>
          {{ user.UserName }}
        </ion-card-title>

        <ion-card-content>
          <ion-label>
            {{ $t('fields.locale') }}
          </ion-label>

          <ion-select
            interface="popover"
            v-model="currentLocale">
            <ion-select-option
              v-for="locale in locales"
              :key="locale"
              :value="locale">
              {{ $t('locales.' + locale)}}
            </ion-select-option>
          </ion-select>

          <ion-button @click="logout" fill="clear" expand="block">
            {{ $t('buttons.logout') }}
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-menu>
  </ion-page>
</template>

<script>
import {
  IonTabBar, IonTabButton, IonTabs, IonIcon, IonLabel, IonContent, IonProgressBar, IonRow, IonCol,
  IonPage, IonRouterOutlet, IonToolbar, IonButton, IonHeader, isPlatform, toastController, IonMenu,
  IonMenuButton, IonCard, IonCardTitle, IonCardSubtitle, IonCardContent, IonButtons, IonBadge,
  IonSelect, IonSelectOption,
} from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import {
  listOutline as listIcon, barcodeOutline as barcodeIcon, carOutline as carIcon,
  chevronBackOutline as backIcon,
} from 'ionicons/icons';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { mapActions, mapGetters, mapState } from 'vuex';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import moment from 'moment';
import { Network } from '@capacitor/network';
import { NativeAudio } from '@capacitor-community/native-audio';
import { App } from '@capacitor/app';
import beep from '../../public/assets/beep.mp3';

export default {
  components: {
    IonContent,
    IonIcon,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonRouterOutlet,
    IonToolbar,
    IonButton,
    IonHeader,
    IonProgressBar,
    IonRow,
    IonCol,
    IonMenu,
    IonMenuButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonButtons,
    IonBadge,
    IonSelect,
    IonSelectOption,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    return {
      route,
      router,
    };
  },
  data() {
    return {
      loading: false,
      previousUpdate: null,
      isActive: true,
      previousBeep: null,
      carIcon,
      backIcon,
      listIcon,
      barcodeIcon,
      wakeLock: null,
      clearModifiedResas: null,
      beep,
    };
  },
  methods: {
    ...mapActions([
      'setScanningState', 'watchDriverChanges', 'getExcursion', 'getReservations', 'setPageTitle', 'offline', 'online',
      'toggleLoadingExcursion', 'toggleLoadingReservations', 'watchBookNr', 'watchDriver', 'watchDelay', 'fetchUser',
      'getExcursions', 'setLocale', 'logout', 'storeReservations', 'loadCachedExcursion', 'loadCachedReservations',
      'clearDrivingState', 'watchMonitor',
    ]),
    parsePath(page) {
      return `/excursion/${this.route.params.excdatebusID}/${this.route.params.excursionID}/${this.route.params.busID}/${this.route.params.date}/${page || ''}`;
    },
    goBack() {
      this.router.push('/');
    },
    parseExcursionPath(excursion) {
      if (!excursion) {
        return null;
      }

      const page = this.route?.path?.split('/')?.at(-1);

      return `/excursion/${excursion.ExcDateBus}/${excursion.ExcursCode}/${excursion.BusCode}/${moment(excursion.Date).format('YYYY-MM-DD')}/${page}`;
    },
    stopScan() {
      if (isPlatform('capacitor')) {
        this.setScanningState(false);
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
      }
    },
    loadBeep() {
      if (isPlatform('capacitor')) {
        NativeAudio.preload({
          assetId: 'beep',
          assetPath: 'public/assets/beep.mp3',
          audioChannelNum: 1,
          isUrl: false,
        }).catch(() => {

        });
      }
    },
    playBeep() {
      const timeout = Date.now() - this.previousBeep;

      if (!this.previousBeep || timeout > 2000) {
        this.previousBeep = Date.now();

        if (isPlatform('capacitor')) {
          NativeAudio.play({
            assetId: 'beep',
          });
        } else {
          (new Audio(this.beep)).play();
        }
      }
    },
    fetchExcursion() {
      const params = {
        EXCURS: this.route.params.excursionID,
        EXCDATEBUS: this.route.params.excdatebusID,
        BUS: this.route.params.busID,
        DATE: this.route.params.date,
      };

      if (this.isDriver) {
        params.DRIVER = this.user.Driver;
      } if (this.isGuide) {
        params.GUIDE = this.user.Guide;
      }

      return this.getExcursion(params).then((excursion) => {
        if (!excursion) {
          this.clearDrivingState(this.route.params.excdatebusID);
          window.location.href = '/';
        }
      }).catch((error) => {
        toastController
          .create({
            message: error.message,
            color: 'danger',
            duration: 2000,
          }).then((toast) => toast.present());
      });
    },
    fetchReservations() {
      const params = {
        EXCURS: this.route.params.excursionID,
        EXCDATEBUS: this.route.params.excdatebusID,
        BUS: this.route.params.busID,
        DATE: this.route.params.date,
        EXCDATE: this.excursion?.ExcDate,
      };

      if (this.isDriver) {
        params.DRIVER = this.user.Driver;
      } if (this.isGuide) {
        params.GUIDE = this.user.Guide;
      }

      return this.getReservations(params).then((resas) => {
        if (params?.EXCDATEBUS === this.route.params.excdatebusID) {
          if (this.clearModifiedResas) {
            clearInterval(this.clearModifiedResas);
          }

          this.storeReservations({ reservations: resas, data: params });

          this.clearModifiedResas = setInterval(() => {
            this.storeReservations({
              reservations: resas.map((resa) => ({
                ...resa,
                modified: false,
              })),
              data: params,
            });
          }, 15000);
        }
      }).catch((error) => {
        toastController
          .create({
            message: error.message,
            color: 'danger',
            duration: 2000,
          }).then((toast) => toast.present());
      });
    },
    loadData() {
      this.loading = true;
      this.previousUpdate = Date.now();

      this.loadCachedExcursion(this.route.params.excdatebusID);
      this.loadCachedReservations(this.route.params.excdatebusID);

      return this.fetchReservations()
        .then(() => this.fetchExcursions())
        .then(() => this.fetchExcursion())
        .then(() => {
          this.loading = false;

          const {
            adults, children, infants, pax,
          } = this.reservations.filter(({ unassigned }) => !unassigned)
            .reduce((total, { CombinedAdults, CombinedChildren, Infants }) => ({
              adults: total.adults + CombinedAdults,
              children: total.children + CombinedChildren,
              infants: total.infants + Infants,
              pax: total.pax + CombinedAdults + CombinedChildren + Infants,
            }), {
              adults: 0,
              children: 0,
              infants: 0,
              pax: 0,
            });

          this.setPageTitle(`${adults} + ${children} + ${infants} = ${pax} | ${this.excursion?.Name || ''}${this.isGuide && this.excursion?.Driver ? ` ${this.excursion?.Driver}` : ''} ${moment(this.route.params.date).format('DD-MM-YYYY')} ${this.excursion?.Start ? moment(this.excursion?.Start).format('HH:mm') : ''}${this.excursion?.End ? ` - ${moment(this.excursion?.End).format('HH:mm')}` : ''}`);
        });
    },
    init() {
      this.toggleLoadingExcursion(true);
      this.toggleLoadingReservations(true);

      this.loadData().then(() => {
        this.toggleLoadingExcursion(false);
        this.toggleLoadingReservations(false);
      }).catch((error) => {
        console.error(error);
        this.toggleLoadingExcursion(false);
        this.toggleLoadingReservations(false);
        this.clearDrivingState(this.route.params.excdatebusID);
        window.location.href = '/';
      });

      if (this.isDriver) {
        this.watchDriver(this.driverID);
      }

      if (this.isGuide) {
        this.watchMonitor();

        this.watchDelay(({ DELAY, DRIVER }) => {
          if (DRIVER === this.driverID) {
            toastController
              .create({
                message: this.$tc('errorCodes.inform-delay', { minutes: DELAY }),
                color: 'warning',
                duration: 2000,
              }).then((toast) => toast.present());
          }
        });
      }

      this.loadBeep();

      this.watchBookNr(() => {
        if (this.route.params.excdatebusID === this.excdatebusID) {
          this.fetchReservations();
        }

        if (this.isGuide) {
          this.playBeep();
        }
      });

      this.watchDriverChanges((params) => {
        if (this.isActive && (this.excdatebusID === params?.EXCDATEBUS
        || this.excdateID === params?.EXCDATE
        || (this.isDriver && (params?.DRIVER === this.driverID
        || params?.DRIVERS?.includes(this.driverID)))
        || this.isDriver)) {
          this.playBeep();
          this.loadData();
        } else if (params?.TYPE === 'PickupsAssignment' && (this.isDriver || params?.GUIDE === this.guideID)) {
          this.playBeep();
          this.loadData();
        } else if (params?.TYPE === 'ExcursionAssignment' && (this.isDriver || params?.GUIDE === this.guideID)) {
          this.playBeep();
          this.loadData();
        } else if (params?.TYPE === 'ExcursionAssignment-guide' && this.isGuide && this.guideID === params?.GUIDE) {
          this.playBeep();
          this.loadData();
        }
      });
    },
    initKeepAwake() {
      try {
        if (isPlatform('capacitor')) {
          KeepAwake.keepAwake();
        } else {
          this.wakeLock = null;

          navigator.wakeLock.request('screen')
            .then((wakeLock) => {
              this.wakeLock = wakeLock;
            })
            .catch((err) => console.error(err));
        }
      } catch (error) {
        console.error('Keep screen on is not available');
        console.error(error);
      }
    },
    fetchExcursions() {
      this.loading = true;

      const params = {
        DATE: this.date,
      };

      if (this.isDriver) {
        params.DRIVER = this.user.Driver;
      } if (this.isGuide) {
        params.GUIDE = this.user.Guide;
      }

      return this.getExcursions(params).then(() => {
        this.loading = false;
      }).catch((error) => {
        this.loading = false;

        toastController
          .create({
            message: error.message,
            color: 'danger',
            duration: 2000,
          }).then((toast) => toast.present());
      });
    },
    setLocaleCode(localeCode) {
      this.setLocale(localeCode).then(() => {
        window.location.reload();
      });
    },
  },
  watch: {
    $route() {
      this.loadCachedExcursion(this.route.params.excdatebusID);

      if (this.route.params.excdatebusID === this.excdatebusID) {
        this.init();
      }
    },
  },
  computed: {
    ...mapState(['isScanning', 'pageTitle', 'user', 'drivingState', 'locales', 'locale', 'excursions', 'excursion', 'reservations']),
    ...mapGetters(['isDriver', 'isGuide', 'viewAllExcursTabs', 'viewSingleDay']),
    currentLocale: {
      get() {
        return this.locale;
      },
      set(value) {
        this.setLocaleCode(value);
      },
    },
    driverID() {
      return this.excursion?.DriverCode;
    },
    guideID() {
      return this.excursion?.GuideCode;
    },
    excdateID() {
      return this.excursion?.ExcDate;
    },
    excdatebusID() {
      return this.excursion?.ExcDateBus;
    },
    date() {
      return this.route?.params?.date;
    },
    drivingTabs() {
      if (this.viewAllExcursTabs) {
        return this.excursions;
      }

      return Object.entries(this.drivingState).filter(([, {
        state, date, excursion,
      }]) => excursion
        && date === this.date
        && state === 'DRIVING').map(([excdatebusID, { excursion, ...rest }]) => ({
        ...rest,
        ...excursion,
        excdatebusID,
      }));
    },
  },
  mounted() {
    if (moment(this.route.params.date).diff(moment(), 'days') < 0) {
      this.clearDrivingState(this.route.params.excdatebusID);
      window.location.href = '/';
      return;
    }

    this.fetchUser({
      CODE: this.user.UserId,
    });

    App.addListener('appStateChange', ({ isActive }) => {
      this.isActive = isActive;

      if (isActive && this.route.params.excdatebusID === this.excdatebusID) {
        this.init();
      }
    });

    Network.getStatus().then(({ connected }) => {
      this.init();

      if (!connected) {
        this.offline();
      }
    });

    Network.addListener('networkStatusChange', ({ connected }) => {
      if (connected) {
        this.online();
        if (this.previousUpdate) {
          const timeout = Date.now() - this.previousUpdate;

          setTimeout(() => {
            if (this.route.params.excdatebusID === this.excdatebusID) {
              const previousReservations = this.reservations.length;

              this.loadData().then(() => {
                if (this.reservations.length !== previousReservations) {
                  this.playBeep();
                }
              });
            }
          }, timeout > 10000 ? 0 : 10000 - timeout);
        } else if (this.route.params.excdatebusID === this.excdatebusID) {
          const previousReservations = this.reservations.length;

          this.loadData().then(() => {
            if (this.reservations.length !== previousReservations) {
              this.playBeep();
            }
          });
        }
      } else {
        this.offline();
      }
    });

    this.initKeepAwake();

    setInterval(() => {
      if (this.route.params.excdatebusID === this.excdatebusID
        && (!this.previousUpdate || Date.now() - this.previousUpdate > 10000)) {
        const previousReservations = this.reservations.length;

        this.loadData().then(() => {
          if (this.reservations.length !== previousReservations) {
            this.playBeep();
          }
        });
      }
    }, 60000);
  },
  beforeUnmount() {
    try {
      if (isPlatform('capacitor')) {
        KeepAwake.allowSleep();
      } else if (this.wakeLock) {
        this.wakeLock.release()
          .then(() => {
            this.wakeLock = null;
          });
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style scoped>
  .text-no-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
