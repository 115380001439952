/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { createStore, createLogger } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { toastController } from '@ionic/vue';
import { createI18n } from 'vue-i18n';
import moment from 'moment';
import api from '../services/api';
import SocketClient from '../services/socket.io.client';
import en from '../locales/en.json';
import el from '../locales/el.json';

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    el,
  },
});

const { t: $t } = i18n.global;

const plugins = [createPersistedState()];

if (process.env.NODE_ENV !== 'production') {
  plugins.push(createLogger());
}

const initState = {
  user: {},
  bearerToken: null,
  generalParameters: {},
  drivingState: {},
  excursion: {},
  excursions: [],
  cachedReservations: {},
  reservations: [],
  pageTitle: null,
  isOnline: true,
  isScanning: false,
  scanResult: null,
  loadingExcursion: false,
  loadingReservations: true,
  offlineToast: null,
  locale: 'en',
  locales: ['en', 'el'],
};

const store = createStore({
  state() {
    return initState;
  },
  getters: {
    isLoggedIn(store) {
      return Boolean(store.user && store.user.UserId);
    },
    isDriver(store) {
      return Boolean(store.user && store.user.Driver);
    },
    isGuide(store) {
      return Boolean(store.user && store.user.Guide);
    },
    viewPickupList() {
      return process.env.VUE_APP_PICKUP_VIEW === 'LIST';
    },
    viewAllExcursTabs() {
      return process.env.VUE_APP_EXCURS_TAB_VIEW === 'ALL';
    },
    viewSingleDay() {
      return process.env.VUE_APP_EXCDATEBUS_VIEW === 'SINGLE_DAY';
    },
    ignoreWrongPickup() {
      return process.env.VUE_APP_WRONG_PICKUP === 'IGNORE';
    },
    ignoreWrongBus() {
      return process.env.VUE_APP_WRONG_BUS === 'IGNORE';
    },
    ignoreWrongDateTime() {
      return process.env.VUE_APP_WRONG_DATETIME === 'IGNORE';
    },
    showDrivingControls() {
      return process.env.VUE_APP_DRIVING_CONTROLS !== 'HIDDEN';
    },
  },
  mutations: {
    SET_USER(state, user) {
      if (user && user[0]) {
        state.user = user && user[0];
      }
    },
    SET_GENERAL_PARAMETERS(state, generalParameters) {
      state.generalParameters = generalParameters;
    },
    SET_BEARER_TOKEN(state, token) {
      state.bearerToken = token;
    },
    SET_DRIVING_STATE(state, { excdatebus, ...data }) {
      state.drivingState[excdatebus] = data;
    },
    CLEAR_DRIVING_STATE(state, excdatebus) {
      delete state.cachedReservations[excdatebus];
      delete state.drivingState[excdatebus];
    },
    SET_NETWORK_STATUS(state, isOnline) {
      state.isOnline = isOnline;
    },
    SET_SCANNING_STATE(state, isScanning) {
      state.isScanning = isScanning;
    },
    SET_SCANNING_RESULT(state, result) {
      state.scanResult = result;
    },
    RESET_BEARER_TOKEN(state) {
      state.bearerToken = null;
    },
    LOGOUT_USER(state) {
      state.user = null;
    },
    CLEAR_APP_DATA(state) {
      Object.keys(state).forEach((key) => {
        state[key] = null;
      });

      Object.keys(initState).forEach((key) => {
        state[key] = initState[key];
      });
    },
    CLEAR_OLD_CACHE(state) {
      state.cachedReservations = {};

      Object.entries(state.drivingState).forEach(([key, { date }]) => {
        if (moment(date).diff(moment(), 'days') < 0) {
          delete state.drivingState[key];
        }
      });
    },
    SET_PAGE_TITLE(state, title) {
      state.pageTitle = title;
    },
    SET_EXCURSION(state, excursion) {
      state.excursion = excursion;
    },
    SET_EXCURSIONS(state, excursions) {
      state.excursions = excursions;
    },
    SET_RESERVATIONS(state, { reservations, excdatebus }) {
      if (excdatebus) {
        state.cachedReservations[excdatebus] = reservations;
      }

      state.reservations = reservations;
    },
    SET_LOADING_EXCURSION(state, loading) {
      state.loadingExcursion = loading;
    },
    SET_LOADING_RESERVATIONS(state, loading) {
      state.loadingReservations = loading;
    },
    SET_LOCALE(state, locale) {
      state.locale = locale;
    },
  },
  actions: {
    toggleLoadingExcursion({ commit }, loading) {
      commit('SET_LOADING_EXCURSION', loading);
    },
    toggleLoadingReservations({ commit }, loading) {
      commit('SET_LOADING_RESERVATIONS', loading);
    },
    login({ commit }, data) {
      return api.login(data).then((user) => {
        if (user && user[0] && (user[0].Driver || user[0].Guide)) {
          commit('SET_USER', user);
        } else {
          throw new Error('invalid-user');
        }
      });
    },
    setLocale({ commit }, locale) {
      i18n.locale = locale;
      i18n.global.locale = locale;
      return commit('SET_LOCALE', locale);
    },
    fetchUser({ commit, dispatch }, data) {
      return api.fetchUser(data).then((user) => {
        if (user && user[0] && (user[0].Driver || user[0].Guide)) {
          commit('SET_USER', user);
        } else {
          dispatch('logout');
          window.location.replace('/');
        }
      });
    },
    logout({ commit }) {
      document.cookie = 'sessionID=;path=/;Max-Age=-99999999;';
      commit('CLEAR_APP_DATA');
      window.location.replace('/');
    },
    setPageTitle({ commit }, title) {
      commit('SET_PAGE_TITLE', title);
    },
    getGeneralParameters({ commit }) {
      return api.getGeneralParameters().then(([generalParameters]) => {
        commit('SET_GENERAL_PARAMETERS', generalParameters);
      });
    },
    getExcursion({ commit }, data) {
      return api.getExcursion(data).then((excursion) => {
        commit('SET_EXCURSION', excursion);
        return excursion;
      });
    },
    changeBus({ dispatch }, data) {
      return api.changeBus(data).then((result) => {
        dispatch('informAvailability', {
          ...data,
          ...result,
        });

        return result;
      });
    },
    changePickup({ dispatch }, data) {
      return api.changePickup(data).then((result) => {
        dispatch('informAvailability', {
          ...data,
          ...result,
        });

        return result;
      });
    },
    watchDriver(state, driver) {
      return SocketClient.watchDriver(driver);
    },
    watchMonitor() {
      return SocketClient.watchMonitor();
    },
    unwatchMonitor() {
      return SocketClient.unwatchMonitor();
    },
    watchDelay(state, callback) {
      return SocketClient.watchDelay(callback);
    },
    watchPickupChange(state, callback) {
      return SocketClient.watchPickupChange(callback);
    },
    setDrivingState({ commit }, data) {
      return commit('SET_DRIVING_STATE', data);
    },
    setScanningState({ commit }, isScanning) {
      return commit('SET_SCANNING_STATE', isScanning);
    },
    checkIn({ dispatch, commit }, params) {
      return api.checkIn(params).then((result) => {
        commit('SET_SCANNING_RESULT', result);
        dispatch('toggleBookNr', params);

        toastController
          .create({
            message: $t('successCodes.check-in-successful'),
            color: 'success',
            duration: 2000,
          }).then((toast) => toast.present());

        return result;
      }).catch(({ response }) => {
        commit('SET_SCANNING_RESULT', null);

        let duration;
        let color;
        let message;
        const buttons = [];

        return new Promise((resolve, reject) => {
          switch (response.data.message) {
            case 'not-paid':
              color = 'warning';
              message = $t('errorCodes.not-paid');
              buttons.push({
                text: $t('buttons.set-as-paid'),
                handler: async () => {
                  await dispatch('setAsPaid', params);
                  await dispatch('checkIn', params);

                  resolve();
                },
              }, {
                text: $t('buttons.cancel'),
                role: 'close',
                handler: () => {
                  reject();
                },
              });
              break;
            case 'already-scanned':
              color = 'warning';
              duration = 4000;
              message = `${$t('errorCodes.already-checked-in')} ${response.data.name || ''} ${$t('fields.Pax')}: ${response.data.adults || 0} + ${response.data.children || 0} + ${response.data.infants || 0}`;
              resolve();
              break;
            case 'wrong-bus':
              color = 'warning';
              message = $t('errorCodes.wrong-bus');
              buttons.push({
                text: $t('buttons.check-in-anyway'),
                handler: () => {
                  dispatch('changeBus', params).then(() => {
                    const { BUS, ...forceCheckInParams } = params;
                    dispatch('checkIn', forceCheckInParams).then(() => {
                      resolve();
                    });
                  });
                },
              }, {
                text: $t('buttons.cancel'),
                role: 'close',
                handler: () => {
                  reject();
                },
              });
              break;
            case 'wrong-pickup':
              color = 'warning';
              message = $t('errorCodes.wrong-pickup');
              buttons.push({
                text: $t('buttons.change-pickup'),
                handler: () => {
                  dispatch('changePickup', params).then(() => {
                    const { PICKUP, ...forceCheckInParams } = params;
                    dispatch('checkIn', forceCheckInParams).then(() => {
                      resolve();
                    });
                  });
                },
              }, {
                text: $t('buttons.keep-pickup'),
                handler: () => {
                  const { PICKUP, ...forceCheckInParams } = params;
                  dispatch('checkIn', forceCheckInParams).then(() => {
                    resolve();
                  });
                },
              }, {
                text: $t('buttons.cancel'),
                role: 'close',
                handler: () => {
                  reject();
                },
              });
              break;
            default:
              color = 'danger';
              duration = 2000;
              message = $t('errorCodes.something-went-wrong');
              reject();
          }

          toastController.dismiss();

          toastController
            .create({
              message,
              color,
              duration,
              buttons,
            }).then((toast) => toast.present());

          if (!buttons || !buttons.length) {
            throw new Error(response.data.message);
          }
        });
      });
    },
    checkOut({ dispatch }, bookNr) {
      return api.checkOut(bookNr).then(() => {
        dispatch('toggleBookNr', bookNr);

        toastController
          .create({
            message: $t('successCodes.check-out-successful'),
            color: 'success',
            duration: 2000,
          }).then((toast) => toast.present());
      }).catch(({ response }) => {
        toastController
          .create({
            message: $t('errorCodes.something-went-wrong'),
            color: 'danger',
            duration: 2000,
          }).then((toast) => toast.present());

        throw new Error(response.data);
      });
    },
    setAsPaid({ dispatch }, params) {
      return api.setAsPaid({
        ...params,
        PAID: '1',
      }).then((result) => {
        dispatch('informAvailability', {
          ...params,
          PAID: '1',
          ...result,
        });

        return result;
      }).catch(({ response }) => {
        toastController
          .create({
            message: $t('errorCodes.something-went-wrong'),
            color: 'danger',
            duration: 2000,
          }).then((toast) => toast.present());

        throw new Error(response.data);
      });
    },
    getExcursions({ commit }, data) {
      return api.getExcursions(data).then((results) => results.map((excursion) => ({
        ...excursion,
        Pax: `${excursion.Adults || 0} + ${excursion.Children || 0} + ${excursion.Infants || 0} = ${excursion.Pax || 0}`,
      }))).then((excursions) => {
        commit('SET_EXCURSIONS', excursions);
        return excursions;
      });
    },
    getReservation(state, data) {
      return api.getReservation(data);
    },
    storeReservations({ commit }, { reservations, data }) {
      commit('SET_RESERVATIONS', { reservations, excdatebus: data.EXCDATEBUS });
    },
    loadCachedReservations({ commit, state }, EXCDATEBUS) {
      const cachedReservations = state.cachedReservations[EXCDATEBUS] || [];

      commit('SET_RESERVATIONS', { reservations: cachedReservations });
    },
    loadCachedExcursion({ commit, state }, EXCDATEBUS) {
      commit('SET_EXCURSION', state.excursions.find(({ ExcDateBus }) => ExcDateBus === EXCDATEBUS) || {});
    },
    async getReservations(store, data) {
      const unassignedReservations = await api.getUnassignedReservations(data)
        .then((data) => data.map((reservation) => ({
          ...reservation,
          CombinedAdults: reservation.Adults,
          CombinedChildren: reservation.Children,
          TicketCombined: `${reservation.TickOrd}${reservation.TickNumb}`,
          Pax: `${reservation.Adults || 0} + ${reservation.Children || 0} + ${reservation.Infants || 0}`,
          unassigned: true,
        }))).catch((error) => console.error(error));

      return api.getReservations(data).then(({ data: results, timestamp }) => {
        let reservations = results.map((reservation) => ({
          ...reservation,
          TicketCombined: `${reservation.TickOrd}${reservation.TickNumb}`,
          Pax: `${reservation.CombinedAdults || 0} + ${reservation.CombinedChildren || 0} + ${reservation.Infants || 0}`,
        }));

        if (unassignedReservations && unassignedReservations.length) {
          reservations.push(...unassignedReservations);
        }

        reservations = reservations.sort(({ Time: a }, { Time: b }) => moment(a, 'HH:mm:ss').diff(moment(b, 'HH:mm:ss'))).map(({ ModificationDate, ...reservation }) => ({
          ...reservation,
          ModificationDate,
          modified: ModificationDate && moment(timestamp).subtract(10, 'seconds').isSameOrBefore(ModificationDate),
        }));

        return reservations;
      });
    },
    signalDriverPosition(state, data) {
      return SocketClient.signalDriverPosition(data);
    },
    arrivedPickup(state, data) {
      return SocketClient.arrivedPickup(data);
    },
    leftPickup(state, data) {
      return SocketClient.leftPickup(data);
    },
    fetchPrice(state, data) {
      return api.fetchPrice(data);
    },
    fetchWaypays(state, params) {
      return api.fetchWaypays(params);
    },
    bookReservation({ dispatch }, data) {
      return api.bookReservation(data).then((result) => {
        dispatch('informAvailability', {
          ...data,
          ...result,
        });

        return result;
      }).catch(({ response }) => {
        if (response) {
          throw new Error(response.data);
        }

        toastController
          .create({
            message: $t('errorCodes.something-went-wrong'),
            color: 'danger',
            duration: 2000,
          }).then((toast) => toast.present());
      });
    },
    informDelay(state, data) {
      SocketClient.informDelay(data);

      toastController
        .create({
          message: $t('successCodes.informed-delay'),
          color: 'success',
          duration: 2000,
        }).then((toast) => toast.present());
    },
    online({ commit, state }) {
      if (!state.isOnline) {
        toastController.dismiss();
        toastController
          .create({
            message: $t('successCodes.you-are-back-online'),
            color: 'success',
            duration: 2000,
          }).then((toast) => toast.present());
      }

      commit('SET_NETWORK_STATUS', true);
    },
    offline({ commit, state }) {
      if (state.isOnline) {
        toastController.dismiss();
        toastController
          .create({
            message: $t('errorCodes.you-are-offline'),
            color: 'danger',
          }).then((toast) => {
            toast.present();
            return toast;
          });
      }

      commit('SET_NETWORK_STATUS', false);
    },
    getExcursionInfo(params) {
      return api.getExcursionInfo(params);
    },
    getPickups(state, data) {
      return api.getExcursionInfo(data).then(({ Pickups }) => Pickups.map((pickup) => ({
        ...pickup,
        text: `${pickup.Pickup} ${pickup.PickupTime}`,
        value: pickup.Id,
      })));
    },
    toggleBookNr(state, data) {
      return SocketClient.toggleBookNr(data);
    },
    watchDriverPosition(state, callback) {
      return SocketClient.watchDriverPosition(callback);
    },
    watchDriverChanges(state, callback) {
      return SocketClient.watchDriverChanges(callback);
    },
    informAvailability(state, data) {
      return SocketClient.informAvailability(data);
    },
    watchBookNr(state, callback) {
      return SocketClient.watchBookNr(callback);
    },
    setBearerToken({ commit }, token) {
      return commit('SET_BEARER_TOKEN', token);
    },
    resetBearerToken({ commit }, token) {
      return commit('RESET_BEARER_TOKEN', token);
    },
    clearData({ commit }) {
      return commit('CLEAR_APP_DATA');
    },
    clearOldCache({ commit }) {
      return commit('CLEAR_OLD_CACHE');
    },
    clearDrivingState({ commit }, excdatebus) {
      return commit('CLEAR_DRIVING_STATE', excdatebus);
    },
  },
  plugins,
});

export default store;
