import axios from 'axios';
import TokenService from './oauth';

class API {
  constructor() {
    axios.defaults.baseURL = process.env.VUE_APP_MULTI_API_IP;

    this.token = null;

    this.request = axios;

    this.mountRequestInterceptor();
    this.mount401Interceptor();
  }

  async setHeader() {
    const token = await TokenService.getBearerToken();

    if (token) {
      this.token = token;
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    return token;
  }

  mountRequestInterceptor() {
    axios.interceptors.request.use(
      async (request) => {
        if (!this.token) {
          await this.setHeader();
        }
        return {
          ...request,
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };
      },
      (error) => Promise.reject(error),
    );
  }

  mount401Interceptor() {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.request && error.request.status === 401) {
          this.token = null;
          await this.setHeader();
          return axios.request(error.config);
        }
        throw error;
      },
    );
  }

  login(params) {
    return this.request.get('34', {
      params,
    }).then(({ data }) => data);
  }

  fetchUser(params) {
    return this.request.get('7', {
      params,
    }).then(({ data }) => data);
  }

  getGeneralParameters() {
    return this.request.get('61').then(({ data }) => data);
  }

  async getExcursion(params) {
    const excursInfo = await this.request.get('10', {
      params,
    }).then(({ data }) => data && data[0]);

    return this.request.get('22', {
      params,
    }).then(({ data }) => {
      if (!data || !data[0]) {
        return null;
      }

      return {
        ...excursInfo,
        ...data[0],
        ...data[0]?.Excursions[0],
      };
    });
  }

  fetchPrice(params) {
    return this.request.get('12', {
      params,
    }).then(({ data }) => data);
  }

  fetchWaypays(params) {
    return this.request.get('28', {
      params,
    }).then(({ data }) => data);
  }

  bookReservation(params) {
    return this.request.post('13', params).then(({ data }) => data);
  }

  setAsPaid({
    BOOKNR, PAID, EXCDATE, PICKUP, BUS,
  }) {
    if (!BOOKNR || !BOOKNR.length) {
      throw new Error('Missing required parameter BOOKNR');
    }

    if (typeof PAID === 'undefined') {
      throw new Error('Missing required parameter PAID');
    }

    return this.request.put('62', {
      BOOKNR, PAID, EXCDATE, PICKUP, BUS,
    }).then(({ data }) => data);
  }

  changeBus({
    BOOKNR, PAID, EXCDATE, PICKUP, PICKUPTIME, BUS,
  }) {
    if (!BOOKNR || !BOOKNR.length) {
      throw new Error('Missing required parameter BOOKNR');
    }

    if (typeof BUS === 'undefined') {
      throw new Error('Missing required parameter BUS');
    }

    return this.request.put('62', {
      BOOKNR,
      PAID,
      EXCDATE,
      PICKUP,
      PICKUPTIME,
      BUS,
      IGNOREFIELDS: ['PICKUP', 'PICKUPTIME'],
    }).then(({ data }) => data);
  }

  changePickup({
    BOOKNR, PAID, EXCDATE, PICKUP, BUS, PICKUPTIME,
  }) {
    if (!BOOKNR || !BOOKNR.length) {
      throw new Error('Missing required parameter BOOKNR');
    }

    if (typeof PICKUP === 'undefined') {
      throw new Error('Missing required parameter PICKUP');
    }

    return this.request.put('62', {
      BOOKNR,
      PAID,
      EXCDATE,
      PICKUP,
      BUS,
      PICKUPTIME,
      IGNOREFIELDS: ['BUS'],
    }).then(({ data }) => data);
  }

  getExcursionInfo(params) {
    return this.request.get('10', {
      params,
    }).then(({ data }) => data && data[0]);
  }

  async getExcursions(params) {
    const excursInfo = await this.request.get('9', {
      params,
    }).then(({ data }) => data.data);

    return this.request.get('22', {
      params,
    }).then(({ data }) => data.map((excurs) => ({
      ...excursInfo.find(({ ExcursCode }) => ExcursCode === excurs.ExcursCode),
      ...excurs,
      ...excurs?.Excursions[0],
    })));
  }

  getTransfers(params) {
    return this.request.get('48', {
      params,
    }).then(({ data }) => data);
  }

  getReservation(params) {
    return this.request.get('11', {
      params,
    }).then(({ data }) => data);
  }

  getReservations(params) {
    return this.request.get('18', {
      params,
    }).then(({ data }) => data);
  }

  getUnassignedReservations(params) {
    return this.request.get('79', {
      params,
    }).then(({ data }) => data);
  }

  checkIn(params) {
    return this.request.post('23', params)
      .then(({ data }) => data);
  }

  checkOut(params) {
    return this.request.delete('24', {
      data: params,
    }).then(({ data }) => data);
  }
}

export default new API();
