<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <slot slot="primary">
          <ion-buttons id="sidenav-toggle">
            <ion-menu-button menu="sidenav"></ion-menu-button>

            <img :height="80" :alt="generalParameters.DetailText1" :src="generalParameters.Logo"/>
          </ion-buttons>
        </slot>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-router-outlet></ion-router-outlet>
    </ion-content>

    <ion-menu v-if="user" side="start" menu-id="sidenav" content-id="sidenav-toggle">
      <ion-card class="ion-padding">
        <ion-card-subtitle>
          <ion-badge v-show="isGuide">
            {{ $t('fields.guide') }}
          </ion-badge>
          <ion-badge v-show="isDriver">
            {{ $t('fields.driver') }}
          </ion-badge>
        </ion-card-subtitle>

        <ion-card-title>
          {{ user.UserName }}
        </ion-card-title>

        <ion-card-content>
          <ion-label>
            {{ $t('fields.locale') }}
          </ion-label>

          <ion-select
            interface="popover"
            v-model="currentLocale">
            <ion-select-option
              v-for="locale in locales"
              :key="locale"
              :value="locale">
              {{ $t('locales.' + locale)}}
            </ion-select-option>
          </ion-select>

          <ion-button @click="logout" fill="clear" expand="block">
            {{ $t('buttons.logout') }}
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-menu>
  </ion-page>
</template>

<script>
import {
  IonToolbar, IonContent, IonPage, IonMenu, IonCard, IonMenuButton, IonButtons, IonRouterOutlet,
  IonCardTitle, IonCardContent, IonButton, IonHeader, IonCardSubtitle, IonBadge,
  IonSelect, IonSelectOption, IonLabel,
} from '@ionic/vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    IonToolbar,
    IonContent,
    IonPage,
    IonMenu,
    IonCard,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonMenuButton,
    IonButtons,
    IonRouterOutlet,
    IonHeader,
    IonCardSubtitle,
    IonBadge,
    IonSelect,
    IonSelectOption,
    IonLabel,
  },
  computed: {
    ...mapState(['user', 'generalParameters', 'locale', 'locales']),
    ...mapGetters(['isGuide', 'isDriver']),
    currentLocale: {
      get() {
        return this.locale;
      },
      set(value) {
        this.setLocaleCode(value);
      },
    },
  },
  methods: {
    ...mapActions(['logout', 'setLocale', 'setPickupView']),
    setLocaleCode(localeCode) {
      this.setLocale(localeCode).then(() => {
        window.location.reload();
      });
    },
  },
};
</script>
