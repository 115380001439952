import { createRouter, createWebHistory } from '@ionic/vue-router';
import ErrorPage from '../pages/ErrorPage.vue';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import ExcursionLayout from '../layouts/ExcursionLayout.vue';
import store from '../store';

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('../pages/HomePage.vue'),
      },
    ],
  },
  {
    path: '/404',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('../pages/ErrorPage.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/LoginPage.vue'),
  },
  {
    path: '/excursion/:excdatebusID/:excursionID/:busID/:date',
    component: ExcursionLayout,
    children: [
      {
        path: '',
        redirect: (to) => `${to.fullPath}/drive`,
      },
      {
        path: 'drive',
        component: () => import('../components/ExcursionDrive.vue'),
      },
      {
        path: 'check-in',
        component: () => import('../components/ExcursionCheckIn.vue'),
      },
      {
        path: 'reservation-list',
        component: () => import('../components/ExcursionReservationList.vue'),
      },
    ],
  },
  {
    path: '/404',
    name: 'Error',
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Login') {
    next();
  } else {
    const { isLoggedIn } = store.getters;

    if (isLoggedIn) {
      next();
    } else {
      next({ name: 'Login' });
    }
  }
});

export default router;
